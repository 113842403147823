var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      class: _vm.dimmedSidebar && "dim",
      attrs: {
        permanent: "",
        "mini-variant": "",
        app: "",
        "mobile-breakpoint": "600",
        "mini-variant-width": "65",
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(
                              {
                                attrs: { dense: "" },
                                on: { click: _vm.toggleSidebarBrightness },
                              },
                              on
                            ),
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-brightness-6")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Dim sidebar")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Dim sidebar")])]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column pa-0" },
        [
          _c(
            "v-sheet",
            {
              staticClass: "d-flex align-center",
              attrs: { height: "48", color: "primary" },
            },
            [
              _c(
                "v-list-item",
                {
                  attrs: {
                    dense: "",
                    to: {
                      name: "home-dashboard",
                      params: { oid: _vm.$route.params.oid },
                      query: _vm.$route.query,
                    },
                  },
                },
                [
                  _c("v-list-item-icon", [
                    _c("img", {
                      attrs: { src: require("@/assets/N-Nuvolos-blue.svg") },
                    }),
                  ]),
                  _c("v-list-item-title", [_vm._v("Dashboard")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-list-item",
                        _vm._g(
                          {
                            attrs: {
                              dense: "",
                              to: {
                                name: "snapshot-overview",
                                params: {
                                  oid: _vm.$route.params.oid,
                                  sid: _vm.$route.params.sid,
                                  iid: _vm.$route.params.iid,
                                  snid: _vm.$route.params.snid,
                                },
                                query: _vm.$route.query,
                              },
                              "e2e-sidebar-overview": "",
                            },
                          },
                          on
                        ),
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.sidebarItems.DASHBOARD.icon)),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.sidebarItems.DASHBOARD.title)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.sidebarItems.DASHBOARD.title))])]
          ),
          _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
            ? _c(
                "div",
                [
                  !_vm.isInstanceObserver
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        {
                                          attrs: {
                                            dense: "",
                                            to: {
                                              name: "snapshot-assignments",
                                              params: {
                                                oid: _vm.$route.params.oid,
                                                sid: _vm.$route.params.sid,
                                                iid: _vm.$route.params.iid,
                                                snid: _vm.$route.params.snid,
                                              },
                                              query: _vm.$route.query,
                                            },
                                            "e2e-sidebar-assignments": "",
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("assignment"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Assignments"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2037426855
                          ),
                        },
                        [_c("span", [_vm._v("Assignments")])]
                      )
                    : _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { disabled: "" } }, [
                                _vm._v("assignment"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "shepherd-space-management" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": true,
                    "offset-x": "",
                    "max-width": "340",
                    "open-on-hover": "",
                    "close-delay": _vm.closingDelay,
                    "e2e-sidebar-settings": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-list-item",
                            _vm._g({ attrs: { dense: "" } }, on),
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(_vm.sidebarItems.SETTINGS.icon)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.sidebarItems.SETTINGS.title)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.manageMenu,
                    callback: function ($$v) {
                      _vm.manageMenu = $$v
                    },
                    expression: "manageMenu",
                  },
                },
                [_c("SettingsMenu")],
                1
              ),
              _c("v-divider"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "shepherd-space-files" },
            [
              !_vm.isInstanceObserver
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {
                                      attrs: {
                                        dense: "",
                                        to: {
                                          name: "snapshot-files",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid,
                                            localPath: _vm.currentFileLocalPath,
                                            fileArea: _vm.fileAreaType,
                                          },
                                          query: _vm.$route.query,
                                        },
                                        "e2e-sidebar-files": "",
                                      },
                                      on: { click: _vm.clearPathArray },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(_vm.sidebarItems.FILES.icon)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(_vm.sidebarItems.FILES.title)
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        137806730
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.sidebarItems.FILES.title))])]
                  )
                : _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { disabled: "" } }, [
                            _vm._v(_vm._s(_vm.sidebarItems.FILES.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "shepherd-space-tables" },
            [
              !_vm.isInstanceObserver
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {
                                      attrs: {
                                        dense: "",
                                        to: {
                                          name: "snapshot-tables",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid,
                                          },
                                          query: _vm.$route.query,
                                        },
                                        "e2e-sidebar-tables": "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(_vm.sidebarItems.TABLES.icon)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(_vm.sidebarItems.TABLES.title)
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4053669535
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.sidebarItems.TABLES.title)),
                      ]),
                    ]
                  )
                : _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { disabled: "" } }, [
                            _vm._v(_vm._s(_vm.sidebarItems.TABLES.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "shepherd-space-apps" },
            [
              !_vm.isInstanceObserver
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {
                                      attrs: {
                                        dense: "",
                                        to: {
                                          name: "snapshot-applications",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid,
                                          },
                                          query: _vm.$route.query,
                                        },
                                        "e2e-sidebar-apps": "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.sidebarItems.APPLICATIONS.icon
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.sidebarItems.APPLICATIONS.title
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2575903652
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.sidebarItems.APPLICATIONS.title)),
                      ]),
                    ]
                  )
                : _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { disabled: "" } }, [
                            _vm._v(_vm._s(_vm.sidebarItems.APPLICATIONS.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c("v-divider"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "shepherd-space-snapshots" },
            [
              !_vm.isInstanceObserver && !_vm.isTrialSpace
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": true,
                        "offset-x": "",
                        "max-width": "300",
                        "open-on-hover": "",
                        "close-delay": _vm.closingDelay,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {
                                      attrs: {
                                        dense: "",
                                        "input-value":
                                          _vm.$route.path.includes(
                                            "manage-snapshots"
                                          ),
                                        to: {
                                          name: "instance-snapshots",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid,
                                          },
                                          query: _vm.$route.query,
                                        },
                                        "e2e-sidebar-snapshots": "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.sidebarItems.SNAPSHOTS.icon
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("snapshot actions"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        385662990
                      ),
                      model: {
                        value: _vm.snapshotMenu,
                        callback: function ($$v) {
                          _vm.snapshotMenu = $$v
                        },
                        expression: "snapshotMenu",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { nav: "", dense: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "justify-start mb-1",
                              attrs: {
                                block: "",
                                color: "primary",
                                elevation: "0",
                                to: {
                                  name: "instance-snapshots",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid,
                                  },
                                  query: _vm.$route.query,
                                },
                                "e2e-sidebar-view-timeline": "",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("timeline")]
                              ),
                              _vm._v(" View snapshot timeline "),
                            ],
                            1
                          ),
                          _vm.currentSpaceType !==
                            _vm.spaceTypes.VENDOR_SPACE &&
                          _vm.isDevelopment &&
                          _vm.isInstanceEditor
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: { disabled: _vm.isSpaceArchived },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSnapshot(
                                        _vm.snapshotTypes.QUICK_SNAPSHOT
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "subtitle-2 d-flex align-center",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-camera-plus-outline")]
                                      ),
                                      _vm._v(" Quick snapshot "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              "grey lighten-1",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-information"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2792354461
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "This will create and save a snapshot of your current state, including all your files, tables, and applications."
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isDevelopment && _vm.isInstanceEditor
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-add-snapshot",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid,
                                      },
                                    },
                                    disabled: _vm.isSpaceArchived,
                                    "e2e-sidebar-take-snapshot": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "subtitle-2 d-flex align-center",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-camera-plus")]
                                      ),
                                      _vm._v(" Take snapshot and describe "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              "grey lighten-1",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-information"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2792354461
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Create a snapshot of your current state and provide detailed description."
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-list-item",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { disabled: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.sidebarItems.SNAPSHOTS.icon
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm.isTrialSpace
                        ? _c("span", [
                            _vm._v(" You discovered a premium feature! "),
                            _c("br"),
                            _vm._v(" Subscribing to Nuvolos will unlock it. "),
                          ])
                        : _c("span", [
                            _vm._v("Snapshots are disabled in this space"),
                          ]),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "shepherd-staging-step-3 shepherd-space-stage shepherd-assignment-step-1",
              on: { click: _vm.stageClicked },
            },
            [
              !_vm.isInstanceObserver
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    {
                                      attrs: {
                                        dense: "",
                                        to: {
                                          name: "snapshot-distribute",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid,
                                          },
                                          query: _vm.$route.query,
                                        },
                                        "e2e-sidebar-sharing": "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-badge",
                                          {
                                            staticClass: "caption",
                                            attrs: {
                                              color:
                                                _vm.numberOfStagedObjects > 0
                                                  ? "primary"
                                                  : "secondary",
                                              overlap: "",
                                              bottom: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "badge",
                                                  fn: function () {
                                                    return [
                                                      _c("v-fab-transition", [
                                                        _c("span", {
                                                          attrs: { fab: "" },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.numberOfStagedObjects
                                                            ),
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.showBadge,
                                              callback: function ($$v) {
                                                _vm.showBadge = $$v
                                              },
                                              expression: "showBadge",
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.sidebarItems.DISTRIBUTION
                                                    .icon
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("distribution"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2306268723
                      ),
                    },
                    [_c("span", [_vm._v("Distribution")])]
                  )
                : _c(
                    "v-list-item",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { disabled: "" } }, [
                            _vm._v(_vm._s(_vm.sidebarItems.DISTRIBUTION.icon)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.$route.query.grading && _vm.isSpaceAdmin
                ? _c("the-snapshot-side-bar-assignment-menu-item")
                : _vm._e(),
              _c("ApplicationMenu"),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }